<script setup lang="ts">
import type { ComputedRef } from 'vue'
import { useModal } from 'vue-final-modal'
import type {
  IManagerTableEmits,
  IManagerTableProps,
} from '@manager/components/Group/Table/types'
import { type INodeObject, parseSizeToGrid, type RepeaterItem } from '@manager'
import { ReviewFormRentReviewTableForm } from '#components'
import type { OptionRentReviewTable, OptionRentReviewType } from './types'
import { useRentReviewData } from './composables'
import type { IOptionsForm } from '@manager/components/Group/Custom/OptionExerciseDetailsTable/types'
import {
  convertToIRentIncreaseTableForm,
  convertToRentReviewAPI,
} from '@manager/components/Group/Custom/RentReviewTable/utils'

const props = defineProps<IManagerTableProps>()
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const [parentValue, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
)

const { data: lease, dateFormat: leaseDateFormat } = useCurrentLease()

const { formValue } = useManagerFormValue<IOptionsForm>()
const leaseStartDate = computed(
  () => formValue.value.supportFieldsGroup?.data?.[0]?.commencementDate,
)
const leaseEndDate = computed(
  () => formValue.value.supportFieldsGroup?.data?.[0]?.expiryDate,
)

const { filteredItems, add, updateById, getById, isEmpty } = useRentReviewData(
  dataModelValue as ComputedRef<OptionRentReviewTable[]>,
  formValue,
)

const itemValue = ref<RepeaterItem<OptionRentReviewTable>>()
const {
  open: openModal,
  close,
  patchOptions,
} = useModal({
  component: ReviewFormRentReviewTableForm,
  attrs: {
    isOption: true,
    isManager: true,
    // @ts-expect-error - Reactivity
    commencementDate: leaseStartDate,
    // @ts-expect-error - Reactivity
    expiryDate: leaseEndDate,
    // @ts-expect-error - Reactivity
    isFirstItem: isEmpty,
    // @ts-expect-error - Reactivity
    disabledDates: computed(() =>
      filteredItems.value
        .map((item) => item.date)
        .filter((date) => itemValue?.value?.Date !== date),
    ),
    // @ts-expect-error - Reactivity
    settings: computed(() => lease.value?.settings),
    onConfirm: (item) => {
      try {
        // Updating
        if (itemValue.value) {
          updateById(itemValue.value.__id, convertToRentReviewAPI(item))
          // Adding
        } else {
          add(convertToRentReviewAPI(item))
        }
      } catch (e) {
        console.error(e)
      }
      close().then(() => {
        itemValue.value = undefined
        patchOptions({
          attrs: {
            rentIncrease: undefined,
          },
        })
      })
    },
    onClose: () => {
      close().then(() => {
        itemValue.value = undefined
        patchOptions({
          attrs: {
            rentIncrease: undefined,
          },
        })
      })
    },
  },
})

const onEdit = (id: string) => {
  const item = getById(id)
  if (item) {
    itemValue.value = item
    patchOptions({
      attrs: {
        rentIncrease: convertToIRentIncreaseTableForm(item),
      },
    })
    openModal()
  }
}
</script>

<template>
  <div :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']">
    <ReviewFormRentReviewTable
      :data="filteredItems"
      :expiry-date="leaseEndDate"
      :commencement-date="leaseStartDate"
      :lease-date-format="leaseDateFormat"
      type="option"
      show-method
      show-rent
      @edit="({ __id }) => onEdit(__id)"
    />
  </div>
</template>
